.country-flag-selector {
    position: relative;
    display: inline-block;
  }
  
  .selected-flag {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px; /* Adjust this value to position the flag */
  }
  