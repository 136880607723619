.card-cus {
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	height: 400px;
	width: 90%;
	cursor: pointer;
}
.card-hdr {
	padding-bottom: 0;
	padding-top: 0;
}
.card-lbl {
	text-align: left;
	width: 100%;
}
.card-tltl {
	font-size: 15px;
}
.card-subtltl {
	font-size: 10px;
}
.card-bdy {
	padding-bottom: 0;
	padding-top: 0;
	position: relative;
}
.card-bdy-img {
	object-fit: cover;
	width: 100%;
	height: 300px;
	border-radius: 12px;
	padding-bottom: 0;
}
.card-badge {
	position: absolute;
	bottom: 5px;
	right: 20px;
	border-radius: 0 0 0 0;
	font-size: 12px;
}
.card-ftr {
	padding-top: 0;
	padding-bottom: 8px;
	margin-bottom: 0;
}
.card-ftr-btn {
	font-size: 12px;
	padding-top: 4px;
	padding-bottom: 4px;
}
