.employee-card .employee-name {
	font-size: 20px;
}

.employee-card .rating-container {
	display: flex;
	align-items: center;
}

.employee-card .rating-score {
	font-size: 22px;
	margin-bottom: 1px;
}

.employee-card .rating-stars {
	margin-bottom: 6px;
	margin-left: 9px;
}

.employee-card figcaption {
	font-size: 15px;
	color: gray;
}

.emp-details-gallery-img {
	width: 125px;
	height: 125px;
	object-fit: cover;
	border-radius: 5px;
}

.employee-details .emp-details-name {
	margin-left: 10px;
	font-size: 18px;
}

.employee-details .rating-score {
	font-size: 22px;
	margin-bottom: 1px;
	font-weight: 3px;
}

.employee-details figcaption {
	font-size: 15px;
	color: gray;
}

@media (max-width: 740px) {
	.employee-card {
		font-size: 16px;
	}

	.employee-card .avatar {
		width: 70px;
		height: 70px;
	}

	.employee-card .employee-name {
		font-size: 16px;
	}

	.employee-card .rating-score {
		font-size: 16px;
	}

	.employee-card .rating-stars {
		margin-bottom: 6px;
		margin-left: 9px;
	}

	.employee-card figcaption {
		font-size: 12px;
		color: gray;
	}

	.emp-details-gallery-img {
		width: 110px;
		height: 110px;
		object-fit: cover;
		border-radius: 5px;
	}

	.employee-details .emp-details-name {
		margin-left: 10px;
		font-size: 16px;
	}

	.employee-details .rating-score {
		font-size: 16px;
		margin-bottom: -1px;
	}

	.employee-details figcaption {
		font-size: 12px;
		color: gray;
	}
}

.PhotoView-Slider__ArrowRight {
	right: 1%;
}

.PhotoView-Slider__ArrowLeft {
	left: 1%;
}

.PhotoView-Slider__ArrowLeft svg,
.PhotoView-Slider__ArrowRight svg {
	background-color: #f2f3f5;
	border-radius: 50%;
	fill: black;
}
.PhotoView-Slider__BannerRight {
	margin-top: 15px;
	margin-right: 15px;
}
.PhotoView-Slider__toolbarIcon {
	background-color: #f2f3f5;
	border-radius: 10px;
	fill: black;
}
.PhotoView-Slider__BannerWrap {
	background-color: transparent;
}
