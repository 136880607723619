/* .payment-header {
} */

.card-title {
	align-content: end;
}

.card-background {
	background-color: transparent;
	box-shadow: none;
}

.payment-header-image {
	justify-self: start;
	margin-left: auto;
	margin-right: 20px;
	max-width: 200px;
	aspect-ratio: 4/1;
}

.payment-list {
	/* border: 1px solid #cccccc31; */
	width: 69%;
	background-color: transparent;
	box-shadow: none;
	margin-bottom: 0%;
}

.payment-right-panel {
	width: 29%;
}

.payment-method-body {
	max-height: 400px;
	/* overflow: scroll; */
}

.payment-method-item {
	cursor: pointer;
	height: 60px;
	padding: 5px;
	border-radius: 5px;
	margin-bottom: 5px;
	align-content: center;
	/* border: 1px solid #cccccc31; */
	background-color: #2d2f39b5;
}

.payment-method-icon {
	width: 80px;
	object-fit: contain;
	padding: 5px;
	background-color: white;
	overflow: hidden;
}

.payment-method-name {
	padding-left: 10px;
	font-size: medium;
}

.payment-method-radio {
	padding-left: 15px;
	padding-right: 15px;
}

.payment-summary-item {
	display: flex;
	justify-content: space-between;
	padding: 3px;
}

.payment-summary-button {
	height: 40px;
}

.payment-summary-button.disabled-btn {
	background-color: #ccc;
	color: #666;
	cursor: not-allowed;
}

.order-summary-tr {
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: #141414;
}

.payment-split-input {
	height: 45px;
}

.bank-transfer-details {
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	transition: opacity 0.5s ease, max-height 0.5s ease;
	background-color: #2d2f39b5;
	padding: 0 15px;
	border-radius: 8px;
	margin-top: 1px;
}

.bank-transfer-details.active {
	opacity: 1;
	max-height: 260px;
	padding: 15px;
}

.bank-transfer-details h5 {
	font-size: 1.2rem;
	color: #fff;
	margin-bottom: 10px;
}

.bank-transfer-details p {
	margin: 5px 0;
}

.payment-title {
	padding-bottom: 5px;
}

@media (max-width: 1250px) {
	.payment-list {
		width: 100%;
		margin-bottom: 10px;
	}

	.payment-right-panel {
		width: 100%;
	}

	.payment-title {
		padding-bottom: 7px;
	}
}
