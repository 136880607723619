.bg-img {
	background-image: url(../../../assets/img/abstract/Main_a.jpg);
	/* width: 100%;
	height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;  */
	margin: 0;
	padding: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: auto;
	min-height: 100vh; /* height: 100%; */ /* height: -webkit-fill-available; */
}
