.main_inner_wrapper {
	width: 100%;
	display: block;
	/* width: 100%;
	display: flex; 
	flex-wrap: wrap; 
	justify-content: center; 
	align-items: center;  */

}

.sub_inner_wrapper {
	/* float: left;
	width: 100%;
	margin-top: 30px; */
	width: 20%;
	float: left;
	/* margin: 24px auto; */
}

@media (max-width: 3000px) {
	.sub_inner_wrapper {
		width: 12.5%;
	}
}

@media (max-width: 2750px) {
	.sub_inner_wrapper {
		width: 14.1%;
	}
}

@media (max-width: 2560px) {
	.sub_inner_wrapper {
		width: 20%;
	}
}

@media (max-width: 1980px) {
	.sub_inner_wrapper {
		width: 20%;
	}
}

@media (max-width: 1886px) {
	.sub_inner_wrapper {
		width: 25%;
	}
}

@media (max-width: 1552px) {
	.sub_inner_wrapper {
		width: 33.3%;
	}
}

@media (max-width: 1210px) {
	.sub_inner_wrapper {
		width: 50%;
	}

	.sub_inner_wrapper .card.card-cus {
		margin: 20px auto;
	}
}

@media (max-width: 673px) {
	.sub_inner_wrapper {
		width: 100%;
		margin-top: 0px;
	}
}

.csl-slide {
	display: flex;
}
.hdr-btn {
	border-radius: 10px;
}
.vslider-dev {
	aspect-ratio: 16/5;
	max-height: 100%;
	max-width: 99%;
	align-self: center;
	border-radius: 10px;
	overflow: hidden;
}
